
import { Component, Vue, Watch } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { PageMode } from '@/utils/type'
import { apiChannelProvideGradeAdd, apiChannelProvideGradeDetail, apiChannelProvideGradeEdit } from '@/api/channel_provider'
@Component({
  components: {
      MaterialSelect
  }
})
export default class GradeEdit extends Vue {
  /** S Data **/
  mode: string = PageMode.ADD // 当前页面【add: 添加渠道商等级 | edit: 编辑渠道商等级】
  identity: number | null = null // 当前编辑渠道商的身份ID  valid: mode = 'edit'
  isLevel: number | null = null // 渠道商等级

  disabled: any = false //是否禁用 查看详情时禁用

  form = {
      name: '', // 等级名称
      rank: 0, // 级别
      remark: '', // 备注
      discount: 10, // 等级折扣

      
  }

  formRules = {
      name: [
          {
              required: true,
              message: '请输入等级名称',
              trigger: 'blur'
          }
      ],
      rank: [
          {
              required: true,
              message: '请输入等级级别',
              trigger: 'blur'
          },
          {
              type: 'number',
              // min: 2,
              required: true,
              message: '请输入大于1的整数',
              trigger: 'blur'
          }
      ],
      discount: [
          {
              required: true,
              message: '请选择等级折扣',
              trigger: 'change'
          }
      ],
  }
  $refs!: {
      formRef: any
  }
  /** E Data **/

  /** S Methods **/
  // 表单提交
  onSubmit() {
      // 验证表单格式是否正确
      this.$refs.formRef.validate((valid: boolean): any => {
          if (!valid) {
              return
          }

          // 请求发送
          switch (this.mode) {
              case PageMode.ADD:
                  return this.handleChannelProvideGradeAdd()
              case PageMode.EDIT:
                  return this.handleChannelProvideGradeEdit()
          }
      })
  }

  // 新增渠道商等级
  handleChannelProvideGradeAdd() {
      // @ts-ignore
      const form = this.form
      apiChannelProvideGradeAdd(form)
          .then(() => {
              // this.$message.success('添加成功!')
              setTimeout(() => this.$router.go(-1), 500)
          })
          .catch(() => {
              //this.$message.error('保存失败!')
          })
  }

  // 编辑渠道商等级
  handleChannelProvideGradeEdit() {
      const params = this.form
      const id: number = this.identity as number
      // @ts-ignore
      apiChannelProvideGradeEdit({ ...params, id } )
          .then(() => {
              // this.$message.success('修改成功!')
              setTimeout(() => this.$router.go(-1), 500)
              //this.initFormDataForChannelProvideGradeEdit()
          })
          .catch(() => {
              // this.$message.error('保存失败!')
          })
  }
  // 表单初始化数据 [编辑模式] mode => edit
  initFormDataForChannelProvideGradeEdit() {
      apiChannelProvideGradeDetail({
          id: this.identity as number
      })
          .then((res: any) => {
              Object.keys(res).map(key => {
                  this.$set(this.form, key, res[key])
              })
          })
          .catch(() => {
              // this.$message.error('数据初始化失败，请刷新重载！')
          })
  }
  // 复选框变化
  conditionChange(val: any, type: string) {
      if (!val) {
          // @ts-ignore
          this.form.condition[type] = ''
      }
  }
  /** E Methods **/

  /** S Life Cycle **/
  created() {
      const query: any = this.$route.query

      if (query.mode) {
          this.mode = query.mode
      }
      this.disabled = this.$route.query.disabled != null ? true : false

      // 编辑模式：初始化数据
      if (this.mode === PageMode.EDIT) {
          this.identity = query.id * 1
          this.isLevel = query.level * 1
          this.initFormDataForChannelProvideGradeEdit()
      }
  }
  /** E Life Cycle **/
  // 监听等级条件是否有输入，输入即勾选
}
